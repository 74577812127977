@charset "UTF-8";
/* >= Tablet min-width: 740px */
/* PC medium resolution > min-width: 1113px */
/* Tablet - PC low resolution (min-width: 740px) and (max-width: 1023px)*/
/* > PC low resolution (min-width: 1024px) and (max-width: 1239px)*/
.grid {
  width: 100%;
  display: block;
  padding: 0; }

.grid.wide {
  max-width: 1000px;
  margin: 0 auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px; }

.row.no-gutters {
  margin-left: 0;
  margin-right: 0; }

.col {
  padding-left: 4px;
  padding-right: 4px; }

.row.no-gutters .col {
  padding-left: 0;
  padding-right: 0; }

.c-0 {
  display: none; }

.c-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.c-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.c-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.c-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.c-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.c-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.c-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.c-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.c-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.c-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.c-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.c-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.c-o-1 {
  margin-left: 8.33333%; }

.c-o-2 {
  margin-left: 16.66667%; }

.c-o-3 {
  margin-left: 25%; }

.c-o-4 {
  margin-left: 33.33333%; }

.c-o-5 {
  margin-left: 41.66667%; }

.c-o-6 {
  margin-left: 50%; }

.c-o-7 {
  margin-left: 58.33333%; }

.c-o-8 {
  margin-left: 66.66667%; }

.c-o-9 {
  margin-left: 75%; }

.c-o-10 {
  margin-left: 83.33333%; }

.c-o-11 {
  margin-left: 91.66667%; }

/* >= Tablet */
@media (min-width: 740px) {
  .row {
    margin-left: -8px;
    margin-right: -8px; }
  .col {
    padding-left: 8px;
    padding-right: 8px; }
  .m-0 {
    display: none; }
  .m-1,
  .m-2,
  .m-3,
  .m-4,
  .m-5,
  .m-6,
  .m-7,
  .m-8,
  .m-9,
  .m-10,
  .m-11,
  .m-12 {
    display: block; }
  .m-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .m-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .m-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .m-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .m-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .m-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .m-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .m-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .m-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .m-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .m-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .m-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .m-o-1 {
    margin-left: 8.33333%; }
  .m-o-2 {
    margin-left: 16.66667%; }
  .m-o-3 {
    margin-left: 25%; }
  .m-o-4 {
    margin-left: 33.33333%; }
  .m-o-5 {
    margin-left: 41.66667%; }
  .m-o-6 {
    margin-left: 50%; }
  .m-o-7 {
    margin-left: 58.33333%; }
  .m-o-8 {
    margin-left: 66.66667%; }
  .m-o-9 {
    margin-left: 75%; }
  .m-o-10 {
    margin-left: 83.33333%; }
  .m-o-11 {
    margin-left: 91.66667%; } }

/* PC medium resolution > */
@media (min-width: 1113px) {
  .row {
    margin-left: -12px;
    margin-right: -12px; }
  .row.sm-gutter {
    margin-left: -5px;
    margin-right: -5px; }
  .col {
    padding-left: 12px;
    padding-right: 12px; }
  .row.sm-gutter .col {
    padding-left: 5px;
    padding-right: 5px; }
  .l-0 {
    display: none; }
  .l-1,
  .l-2,
  .l-2-4,
  .l-3,
  .l-4,
  .l-5,
  .l-6,
  .l-7,
  .l-8,
  .l-9,
  .l-10,
  .l-11,
  .l-12 {
    display: block; }
  .l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .l-2-4 {
    flex: 0 0 20%;
    max-width: 20%; }
  .l-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .l-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .l-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .l-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .l-o-1 {
    margin-left: 8.33333%; }
  .l-o-2 {
    margin-left: 16.66667%; }
  .l-o-3 {
    margin-left: 25%; }
  .l-o-4 {
    margin-left: 33.33333%; }
  .l-o-5 {
    margin-left: 41.66667%; }
  .l-o-6 {
    margin-left: 50%; }
  .l-o-7 {
    margin-left: 58.33333%; }
  .l-o-8 {
    margin-left: 66.66667%; }
  .l-o-9 {
    margin-left: 75%; }
  .l-o-10 {
    margin-left: 83.33333%; }
  .l-o-11 {
    margin-left: 91.66667%; } }

/* Tablet - PC low resolution */
@media (min-width: 740px) and (max-width: 1023px) {
  .wide {
    width: 644px; } }

/* > PC low resolution */
@media (min-width: 1024px) and (max-width: 1239px) {
  .wide {
    width: 984px; }
  .wide .row {
    margin-left: -12px;
    margin-right: -12px; }
  .wide .row.sm-gutter {
    margin-left: -5px;
    margin-right: -5px; }
  .wide .col {
    padding-left: 12px;
    padding-right: 12px; }
  .wide .row.sm-gutter .col {
    padding-left: 5px;
    padding-right: 5px; }
  .wide .l-0 {
    display: none; }
  .wide .l-1,
  .wide .l-2,
  .wide .l-2-4,
  .wide .l-3,
  .wide .l-4,
  .wide .l-5,
  .wide .l-6,
  .wide .l-7,
  .wide .l-8,
  .wide .l-9,
  .wide .l-10,
  .wide .l-11,
  .wide .l-12 {
    display: block; }
  .wide .l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .wide .l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .wide .l-2-4 {
    flex: 0 0 20%;
    max-width: 20%; }
  .wide .l-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .wide .l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .wide .l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .wide .l-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .wide .l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .wide .l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .wide .l-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .wide .l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .wide .l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .wide .l-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .wide .l-o-1 {
    margin-left: 8.33333%; }
  .wide .l-o-2 {
    margin-left: 16.66667%; }
  .wide .l-o-3 {
    margin-left: 25%; }
  .wide .l-o-4 {
    margin-left: 33.33333%; }
  .wide .l-o-5 {
    margin-left: 41.66667%; }
  .wide .l-o-6 {
    margin-left: 50%; }
  .wide .l-o-7 {
    margin-left: 58.33333%; }
  .wide .l-o-8 {
    margin-left: 66.66667%; }
  .wide .l-o-9 {
    margin-left: 75%; }
  .wide .l-o-10 {
    margin-left: 83.33333%; }
  .wide .l-o-11 {
    margin-left: 91.66667%; } }

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input,
select {
  vertical-align: middle; }

p,
span {
  padding: 0;
  margin: 0; }

*,
*::after,
*::before {
  box-sizing: border-box; }

html {
  font-size: 62.5%; }

body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 1.8rem;
  overflow-x: hidden;
  font-family: "Noto Sans JP", sans-serif;
  background-color: #F9F6F3; }

.section {
  width: 100%;
  height: 100%; }

.container {
  margin: 0 auto;
  width: 1080px;
  height: 100%; }
  @media (max-width: 1079.98px) {
    .container {
      width: 87%; } }

.container-large {
  margin: 0 auto;
  width: 1200px; }
  @media (max-width: 1200.98px) {
    .container-large {
      width: 95%; } }

@keyframes fadeOut50P {
  0% {
    opacity: 1;
    left: -100%; }
  25% {
    opacity: 1;
    left: 0;
    width: 100vw; }
  75% {
    opacity: 1;
    left: 0;
    width: 100vw; }
  100% {
    opacity: 1;
    left: 100%;
    width: 0vw; } }

@keyframes faceIn50P {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes faceIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fillUp {
  to {
    stroke-dasharray: 0 300; } }

@keyframes hidden {
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes scroll {
  25% {
    transform: translateY(100%); }
  25.1% {
    transform: translateY(-100%); }
  50% {
    transform: translateY(-100%); }
  75% {
    transform: translateY(0%); } }

.grid {
  width: 100%;
  display: block;
  padding: 0; }

.grid.wide {
  max-width: 1000px;
  margin: 0 auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px; }

.row.no-gutters {
  margin-left: 0;
  margin-right: 0; }

.col {
  padding-left: 4px;
  padding-right: 4px; }

.row.no-gutters .col {
  padding-left: 0;
  padding-right: 0; }

.c-0 {
  display: none; }

.c-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.c-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.c-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.c-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.c-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.c-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.c-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.c-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.c-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.c-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.c-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.c-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.c-o-1 {
  margin-left: 8.33333%; }

.c-o-2 {
  margin-left: 16.66667%; }

.c-o-3 {
  margin-left: 25%; }

.c-o-4 {
  margin-left: 33.33333%; }

.c-o-5 {
  margin-left: 41.66667%; }

.c-o-6 {
  margin-left: 50%; }

.c-o-7 {
  margin-left: 58.33333%; }

.c-o-8 {
  margin-left: 66.66667%; }

.c-o-9 {
  margin-left: 75%; }

.c-o-10 {
  margin-left: 83.33333%; }

.c-o-11 {
  margin-left: 91.66667%; }

/* >= Tablet */
@media (min-width: 740px) {
  .row {
    margin-left: -8px;
    margin-right: -8px; }
  .col {
    padding-left: 8px;
    padding-right: 8px; }
  .m-0 {
    display: none; }
  .m-1,
  .m-2,
  .m-3,
  .m-4,
  .m-5,
  .m-6,
  .m-7,
  .m-8,
  .m-9,
  .m-10,
  .m-11,
  .m-12 {
    display: block; }
  .m-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .m-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .m-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .m-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .m-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .m-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .m-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .m-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .m-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .m-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .m-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .m-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .m-o-1 {
    margin-left: 8.33333%; }
  .m-o-2 {
    margin-left: 16.66667%; }
  .m-o-3 {
    margin-left: 25%; }
  .m-o-4 {
    margin-left: 33.33333%; }
  .m-o-5 {
    margin-left: 41.66667%; }
  .m-o-6 {
    margin-left: 50%; }
  .m-o-7 {
    margin-left: 58.33333%; }
  .m-o-8 {
    margin-left: 66.66667%; }
  .m-o-9 {
    margin-left: 75%; }
  .m-o-10 {
    margin-left: 83.33333%; }
  .m-o-11 {
    margin-left: 91.66667%; } }

/* PC medium resolution > */
@media (min-width: 1113px) {
  .row {
    margin-left: -12px;
    margin-right: -12px; }
  .row.sm-gutter {
    margin-left: -5px;
    margin-right: -5px; }
  .col {
    padding-left: 12px;
    padding-right: 12px; }
  .row.sm-gutter .col {
    padding-left: 5px;
    padding-right: 5px; }
  .l-0 {
    display: none; }
  .l-1,
  .l-2,
  .l-2-4,
  .l-3,
  .l-4,
  .l-5,
  .l-6,
  .l-7,
  .l-8,
  .l-9,
  .l-10,
  .l-11,
  .l-12 {
    display: block; }
  .l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .l-2-4 {
    flex: 0 0 20%;
    max-width: 20%; }
  .l-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .l-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .l-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .l-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .l-o-1 {
    margin-left: 8.33333%; }
  .l-o-2 {
    margin-left: 16.66667%; }
  .l-o-3 {
    margin-left: 25%; }
  .l-o-4 {
    margin-left: 33.33333%; }
  .l-o-5 {
    margin-left: 41.66667%; }
  .l-o-6 {
    margin-left: 50%; }
  .l-o-7 {
    margin-left: 58.33333%; }
  .l-o-8 {
    margin-left: 66.66667%; }
  .l-o-9 {
    margin-left: 75%; }
  .l-o-10 {
    margin-left: 83.33333%; }
  .l-o-11 {
    margin-left: 91.66667%; } }

/* Tablet - PC low resolution */
@media (min-width: 740px) and (max-width: 1023px) {
  .wide {
    width: 644px; } }

/* > PC low resolution */
@media (min-width: 1024px) and (max-width: 1239px) {
  .wide {
    width: 984px; }
  .wide .row {
    margin-left: -12px;
    margin-right: -12px; }
  .wide .row.sm-gutter {
    margin-left: -5px;
    margin-right: -5px; }
  .wide .col {
    padding-left: 12px;
    padding-right: 12px; }
  .wide .row.sm-gutter .col {
    padding-left: 5px;
    padding-right: 5px; }
  .wide .l-0 {
    display: none; }
  .wide .l-1,
  .wide .l-2,
  .wide .l-2-4,
  .wide .l-3,
  .wide .l-4,
  .wide .l-5,
  .wide .l-6,
  .wide .l-7,
  .wide .l-8,
  .wide .l-9,
  .wide .l-10,
  .wide .l-11,
  .wide .l-12 {
    display: block; }
  .wide .l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .wide .l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .wide .l-2-4 {
    flex: 0 0 20%;
    max-width: 20%; }
  .wide .l-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .wide .l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .wide .l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .wide .l-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .wide .l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .wide .l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .wide .l-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .wide .l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .wide .l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .wide .l-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .wide .l-o-1 {
    margin-left: 8.33333%; }
  .wide .l-o-2 {
    margin-left: 16.66667%; }
  .wide .l-o-3 {
    margin-left: 25%; }
  .wide .l-o-4 {
    margin-left: 33.33333%; }
  .wide .l-o-5 {
    margin-left: 41.66667%; }
  .wide .l-o-6 {
    margin-left: 50%; }
  .wide .l-o-7 {
    margin-left: 58.33333%; }
  .wide .l-o-8 {
    margin-left: 66.66667%; }
  .wide .l-o-9 {
    margin-left: 75%; }
  .wide .l-o-10 {
    margin-left: 83.33333%; }
  .wide .l-o-11 {
    margin-left: 91.66667%; } }

.section-contact {
  background-color: white;
  padding-top: 60px;
  padding-bottom: 180px; }
  @media (max-width: 575.98px) {
    .section-contact {
      padding-top: 30px;
      padding-bottom: 50px; } }
  .section-contact__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .section-contact__content img {
      max-width: 100%; }

.contact-form {
  background-color: #EDEDED;
  padding: 55px 80px;
  width: 100%; }
  @media (max-width: 1079.98px) {
    .contact-form {
      padding: 55px 45px; } }
  @media (max-width: 768.98px) {
    .contact-form {
      padding: 40px 5% 60px 5%; } }
  @media (max-width: 768.98px) {
    .contact-form {
      padding: 20px 5% 30px 5%; } }
  .contact-form form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .contact-form form .form-control {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 24px; }
      @media (max-width: 768.98px) {
        .contact-form form .form-control {
          display: block; } }
      @media (max-width: 575.98px) {
        .contact-form form .form-control {
          margin-bottom: 15px; } }
      .contact-form form .form-control label {
        flex-basis: 240px;
        position: relative;
        display: inline-block; }
        @media (max-width: 768.98px) {
          .contact-form form .form-control label {
            flex-basis: auto;
            margin-bottom: 13px; } }
        @media (max-width: 575.98px) {
          .contact-form form .form-control label {
            font-size: 16px; } }
        .contact-form form .form-control label span {
          color: white;
          font-size: 14px;
          background-color: #457CDC;
          border-radius: 3px;
          font-weight: bold;
          padding: 2px 3px;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%); }
          @media (max-width: 768.98px) {
            .contact-form form .form-control label span {
              right: auto;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 115%;
              width: 32px;
              height: 20px;
              font-size: 12px; } }
      .contact-form form .form-control input {
        height: 64px; }
        @media (max-width: 575.98px) {
          .contact-form form .form-control input {
            height: 50px; } }
      .contact-form form .form-control input,
      .contact-form form .form-control textarea {
        font-size: 16px;
        font-weight: 400;
        padding: 0 30px;
        border: none;
        border-radius: 10px;
        flex: 1; }
        @media (max-width: 768.98px) {
          .contact-form form .form-control input,
          .contact-form form .form-control textarea {
            width: 100%;
            display: inline-block; } }
        @media (max-width: 575.98px) {
          .contact-form form .form-control input,
          .contact-form form .form-control textarea {
            font-size: 14px;
            padding: 0 20px; } }
        .contact-form form .form-control input:focus,
        .contact-form form .form-control textarea:focus {
          outline: none; }
        .contact-form form .form-control input::placeholder,
        .contact-form form .form-control textarea::placeholder {
          font-size: 16px;
          font-weight: 400;
          color: #b7b7b7; }
          @media (max-width: 575.98px) {
            .contact-form form .form-control input::placeholder,
            .contact-form form .form-control textarea::placeholder {
              font-size: 14px; } }
      .contact-form form .form-control textarea {
        padding-top: 20px;
        padding-bottom: 20px;
        height: 220px; }
        @media (max-width: 575.98px) {
          .contact-form form .form-control textarea {
            height: 150px; } }

.accept-privacy {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 15px; }
  @media (max-width: 575.98px) {
    .accept-privacy {
      margin-bottom: 25px;
      margin-top: 10px; } }
  .accept-privacy input {
    margin-right: 10px;
    width: 16px;
    height: 16px; }
  .accept-privacy a {
    color: #1571da; }

.thank__title {
  margin-top: 140px;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 55px;
  margin-bottom: 46px; }
  @media (max-width: 768.98px) {
    .thank__title {
      margin-top: 308px;
      font-size: 2rem; } }

.thank__content {
  margin-bottom: 40px; }
  .thank__content p {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 35px;
    text-align: center; }
    @media (max-width: 768.98px) {
      .thank__content p {
        font-size: 1.6rem; } }

.btn-back {
  width: 412px;
  height: 82px;
  font-size: 2.2rem; }

.section-diagnostic {
  padding-bottom: 110px;
  margin-top: 70px;
  background-color: #ffffff; }
  @media (max-width: 768.98px) {
    .section-diagnostic {
      padding-bottom: 50px; } }
  .section-diagnostic__content-1 {
    background-color: #457CDC;
    height: 254px;
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    padding-top: 35px;
    padding-left: 10%;
    padding-right: 10%; }
    @media (max-width: 768.98px) {
      .section-diagnostic__content-1 {
        height: 230px; } }
    @media (max-width: 575.98px) {
      .section-diagnostic__content-1 {
        height: 165px; } }
    .section-diagnostic__content-1 img {
      max-width: 100%; }
    .section-diagnostic__content-1 p {
      position: absolute;
      top: -25px;
      font-size: 3.2rem;
      font-weight: bold;
      z-index: 0;
      width: fit-content; }
      @media (max-width: 575.98px) {
        .section-diagnostic__content-1 p {
          font-size: 24px; } }
      .section-diagnostic__content-1 p::after {
        position: absolute;
        content: "";
        width: 105%;
        height: 75%;
        bottom: -7px;
        z-index: -1;
        background-color: #FFE200;
        left: -3%; }
      .section-diagnostic__content-1 p img {
        width: auto;
        height: auto; }

.diagnostic-main-1 {
  margin-bottom: 110px;
  margin-top: -35px;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1079.98px) {
    .diagnostic-main-1 {
      flex-direction: column;
      margin-top: 0;
      justify-content: flex-start;
      margin-bottom: 0; } }
  .diagnostic-main-1__item {
    display: flex;
    align-items: center;
    width: calc(100% / 3 - 30px);
    flex-direction: column; }
    @media (max-width: 1079.98px) {
      .diagnostic-main-1__item {
        flex-direction: row;
        width: 100%;
        position: relative;
        margin-bottom: 65px; } }
    @media (max-width: 768.98px) {
      .diagnostic-main-1__item {
        margin-bottom: 75px; } }
    @media (max-width: 575.98px) {
      .diagnostic-main-1__item {
        margin-bottom: 45px; } }
    .diagnostic-main-1__item--title {
      position: relative; }
      @media (max-width: 1079.98px) {
        .diagnostic-main-1__item--title {
          position: absolute;
          display: flex;
          align-items: center;
          top: -43px;
          left: 150px; } }
      @media (max-width: 768.98px) {
        .diagnostic-main-1__item--title {
          left: 88px;
          top: -54px; } }
      @media (max-width: 575.98px) {
        .diagnostic-main-1__item--title {
          left: 35px;
          top: -31px; } }
      .diagnostic-main-1__item--title img.pc {
        width: auto;
        height: auto;
        max-width: 100%; }
        @media (max-width: 1079.98px) {
          .diagnostic-main-1__item--title img.pc {
            display: none; } }
      .diagnostic-main-1__item--title img.sp {
        display: none; }
        @media (max-width: 1079.98px) {
          .diagnostic-main-1__item--title img.sp {
            display: block;
            max-width: 100%; } }
        @media (max-width: 768.98px) {
          .diagnostic-main-1__item--title img.sp {
            display: block;
            max-width: 100%; } }
        @media (max-width: 575.98px) {
          .diagnostic-main-1__item--title img.sp {
            display: block;
            max-width: 100%;
            height: 55px; } }
      .diagnostic-main-1__item--title p {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: fit-content;
        font-size: 2rem;
        font-weight: bold;
        width: 100%;
        text-align: center; }
        @media (max-width: 1079.98px) {
          .diagnostic-main-1__item--title p {
            top: 30px;
            font-size: 25px; } }
        @media (max-width: 768.98px) {
          .diagnostic-main-1__item--title p {
            top: 30px;
            font-size: 25px; } }
        @media (max-width: 575.98px) {
          .diagnostic-main-1__item--title p {
            font-size: 14px;
            top: 16px; } }
    .diagnostic-main-1__item--note {
      width: 280px;
      height: 280px;
      border-radius: 50%;
      background-color: #F9F6F3;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -40px; }
      @media (max-width: 1079.98px) {
        .diagnostic-main-1__item--note {
          width: 350px;
          height: 350px;
          margin-top: 0; } }
      @media (max-width: 768.98px) {
        .diagnostic-main-1__item--note {
          width: 230px;
          height: 230px; } }
      @media (max-width: 575.98px) {
        .diagnostic-main-1__item--note {
          width: 110px;
          height: 110px; } }
      .diagnostic-main-1__item--note p {
        font-weight: bold;
        font-size: 6.3rem;
        text-align: center; }
        .diagnostic-main-1__item--note p span {
          font-size: 3.2rem;
          display: block;
          line-height: 50px; }
          @media (max-width: 768.98px) {
            .diagnostic-main-1__item--note p span {
              font-size: 20px; } }
          @media (max-width: 575.98px) {
            .diagnostic-main-1__item--note p span {
              font-size: 15px;
              line-height: 30px; } }
        @media (max-width: 768.98px) {
          .diagnostic-main-1__item--note p {
            font-size: 44px;
            line-height: 45px; } }
        @media (max-width: 575.98px) {
          .diagnostic-main-1__item--note p {
            font-size: 20px;
            line-height: 20px;
            margin-top: 17px; } }
    .diagnostic-main-1__item--content {
      padding: 0 25px;
      margin-top: -25px; }
      @media (max-width: 1079.98px) {
        .diagnostic-main-1__item--content {
          flex: 1; } }
      @media (max-width: 768.98px) {
        .diagnostic-main-1__item--content {
          padding-right: 0;
          padding-left: 15px;
          padding-top: 20px; } }
      .diagnostic-main-1__item--content p {
        line-height: 25px;
        font-weight: 300;
        text-align: justify; }
        @media (max-width: 1079.98px) {
          .diagnostic-main-1__item--content p {
            font-size: 30px;
            line-height: 45px; } }
        @media (max-width: 768.98px) {
          .diagnostic-main-1__item--content p {
            font-size: 25px;
            line-height: 32px; } }
        @media (max-width: 575.98px) {
          .diagnostic-main-1__item--content p {
            font-size: 14px;
            line-height: 20px; } }
        @media (max-width: 375.98px) {
          .diagnostic-main-1__item--content p {
            padding-top: 13px; } }

.diagnostic-main-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; }
  @media (max-width: 768.98px) {
    .diagnostic-main-2 {
      margin-top: 15px; } }
  .diagnostic-main-2::before {
    content: "";
    width: 800px;
    background-image: linear-gradient(90deg, #ffe200 40%, white 40%);
    background-position: right;
    background-size: 10px 10px;
    background-repeat: repeat-x;
    height: 4px;
    top: 0; }
    @media (max-width: 1079.98px) {
      .diagnostic-main-2::before {
        width: 100%; } }
  .diagnostic-main-2::after {
    content: "";
    width: 800px;
    height: 4px;
    bottom: 0;
    background-image: linear-gradient(90deg, #ffe200 40%, white 40%);
    background-position: right;
    background-size: 10px 10px;
    background-repeat: repeat-x; }
    @media (max-width: 1079.98px) {
      .diagnostic-main-2::after {
        width: 100%; } }
  .diagnostic-main-2 .main-text {
    color: #EB0E6B;
    letter-spacing: 0.4rem;
    text-align: center;
    font-weight: bold; }
    .diagnostic-main-2 .main-text--1 {
      font-size: 7.6rem;
      line-height: 6.5rem;
      margin-bottom: 17px;
      margin-top: 30px; }
      @media (max-width: 1079.98px) {
        .diagnostic-main-2 .main-text--1 {
          font-size: 40px;
          line-height: 45px;
          margin-bottom: 0; } }
      @media (max-width: 575.98px) {
        .diagnostic-main-2 .main-text--1 {
          font-size: 30px;
          line-height: 45px; } }
    .diagnostic-main-2 .main-text--2 {
      font-size: 5rem;
      line-height: 6.5rem;
      text-align: center;
      margin-left: 17px;
      margin-bottom: 80px; }
      @media (max-width: 1079.98px) {
        .diagnostic-main-2 .main-text--2 {
          font-size: 25px;
          line-height: 45px;
          margin-bottom: 60px; } }
      @media (max-width: 575.98px) {
        .diagnostic-main-2 .main-text--2 {
          font-size: 17px;
          line-height: 24px;
          margin-bottom: 60px; } }
  .diagnostic-main-2--img-1 {
    position: absolute;
    top: -45px;
    left: 20px;
    width: auto;
    height: auto; }
    @media (max-width: 991.98px) {
      .diagnostic-main-2--img-1 {
        width: 160px;
        top: -70px;
        left: 12px; } }
    @media (max-width: 768.98px) {
      .diagnostic-main-2--img-1 {
        left: -6%;
        width: 160px; } }
    @media (max-width: 575.98px) {
      .diagnostic-main-2--img-1 {
        width: 85px;
        top: -40px; } }
  .diagnostic-main-2--img-2 {
    position: absolute;
    top: -40px;
    right: 85px;
    width: auto;
    height: auto; }
    @media (max-width: 991.98px) {
      .diagnostic-main-2--img-2 {
        right: 63px; } }
    @media (max-width: 768.98px) {
      .diagnostic-main-2--img-2 {
        right: -4%; } }
    @media (max-width: 575.98px) {
      .diagnostic-main-2--img-2 {
        width: 75px; } }

.diagnostic-main-3 {
  display: flex;
  justify-content: center;
  margin-top: -50px; }
  @media (max-width: 575.98px) {
    .diagnostic-main-3 {
      margin-top: -40px; } }
  @media (max-width: 575.98px) {
    .diagnostic-main-3 .main-btn {
      width: 80%;
      height: 65px; } }

.footer {
  background-color: white; }
  .footer__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 40px; }
  .footer__address {
    background: #457CDC;
    padding-top: 12px; }
    @media (max-width: 575.98px) {
      .footer__address {
        padding-top: 10px; } }
    .footer__address span {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 30px;
      color: #FFFFFF; }
      .footer__address span:nth-child(2) {
        padding-left: 30px; }
        @media (max-width: 575.98px) {
          .footer__address span:nth-child(2) {
            padding-left: 15px; } }
      @media (max-width: 575.98px) {
        .footer__address span {
          line-height: 23px; } }
    .footer__address p {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 30px;
      color: #FFFFFF;
      margin-top: 12px; }
      @media (max-width: 575.98px) {
        .footer__address p {
          margin-top: 0; } }
    @media (max-width: 1079.98px) {
      .footer__address--pc {
        display: none; } }
    .footer__address--mobile {
      display: none; }
      .footer__address--mobile span,
      .footer__address--mobile p {
        font-size: 2.3rem;
        font-weight: 300; }
        @media (max-width: 575.98px) {
          .footer__address--mobile span,
          .footer__address--mobile p {
            font-size: 14px; } }
      @media (max-width: 1079.98px) {
        .footer__address--mobile {
          display: block; } }
  .footer__copyright {
    background: #457CDC;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 14px;
    color: #FFFFFF;
    padding-bottom: 22px;
    padding-top: 56px;
    text-align: center; }
    @media (max-width: 1079.98px) {
      .footer__copyright {
        font-size: 1.9rem; } }
    @media (max-width: 575.98px) {
      .footer__copyright {
        font-size: 12px;
        padding-top: 30px; } }
  .footer__content {
    position: relative; }

.horizontal-line {
  border-top: 1px solid #9cb3db;
  margin-top: 8px; }

.btn-circle {
  width: 80px;
  height: 80px;
  background-color: #FFFFFF;
  border-radius: 50%;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 575.98px) {
    .btn-circle {
      width: 42px;
      height: 42px; } }
  .btn-circle:hover {
    background-color: #FFE200; }
    .btn-circle:hover img:nth-child(1) {
      display: none; }
    .btn-circle:hover img:nth-child(2) {
      display: flex; }

.footer_option {
  position: absolute;
  top: -50px;
  right: 16px; }
  @media (max-width: 1079.98px) {
    .footer_option {
      top: -65px; } }
  @media (max-width: 575.98px) {
    .footer_option {
      top: -25px; } }

.section-intro {
  padding-top: 50px;
  position: relative;
  overflow: hidden;
  padding-bottom: 200px;
  z-index: 0; }
  @media (max-width: 768.98px) {
    .section-intro {
      padding-bottom: 170px; } }
  @media (max-width: 575.98px) {
    .section-intro {
      padding-bottom: 50px;
      padding-top: 25px; } }
  .section-intro::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: -25%;
    left: 0;
    background-color: #457CDC;
    z-index: -2; }
  .section-intro::after {
    content: "";
    width: 120%;
    height: 100%;
    background-color: #457CDC;
    position: absolute;
    z-index: -2;
    clip-path: ellipse(55% 25% at 50% 75%);
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%); }
    @media (max-width: 575.98px) {
      .section-intro::after {
        width: 150%; } }
  .section-intro .container-large {
    max-height: 100vh;
    margin-bottom: 200px; }
    @media (max-width: 1079.98px) {
      .section-intro .container-large {
        max-height: none; } }
  .section-intro--bg {
    position: absolute;
    right: -360px;
    top: -360px;
    z-index: -1; }
    @media (max-width: 768.98px) {
      .section-intro--bg {
        right: -50%;
        width: 140%;
        bottom: 0%;
        top: auto; } }

.intro-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4vh; }
  @media (max-width: 768.98px) {
    .intro-main {
      flex-direction: column; } }
  @media (max-width: 768.98px) {
    .intro-main {
      margin-top: 30px; } }
  .intro-main__img {
    margin-right: -9px;
    display: flex;
    align-items: center; }
    .intro-main__img img {
      width: 424px;
      height: 423px; }
      @media (max-width: 1200.98px) {
        .intro-main__img img {
          width: 100%;
          height: auto; } }
    @media (max-width: 1200.98px) {
      .intro-main__img {
        width: 35%; } }
    @media (max-width: 768.98px) {
      .intro-main__img {
        width: 80%;
        margin: 0 auto; } }
  @media (max-width: 768.98px) {
    .intro-main__content {
      width: 90%;
      margin: 0 auto 75px auto; } }
  @media (max-width: 575.98px) {
    .intro-main__content {
      margin: 0 auto 40px auto; } }
  .intro-main__content__main {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff; }
    .intro-main__content__main--p1 {
      font-size: 2.8rem;
      line-height: 72px; }
      @media (max-width: 768.98px) {
        .intro-main__content__main--p1 {
          font-size: 24px;
          line-height: 42px; } }
      @media (max-width: 575.98px) {
        .intro-main__content__main--p1 {
          margin-bottom: 10px;
          font-size: 18px;
          line-height: 32px; } }
    .intro-main__content__main--h1 {
      font-size: 99px;
      font-weight: bold;
      text-align: center;
      line-height: 95px;
      margin-bottom: 20px; }
      @media (max-width: 1200.98px) {
        .intro-main__content__main--h1 {
          font-size: 7.8vw;
          line-height: 6.8vw; } }
      @media (max-width: 768.98px) {
        .intro-main__content__main--h1 {
          font-size: 10vw;
          line-height: 10vw; } }
      @media (max-width: 575.98px) {
        .intro-main__content__main--h1 {
          margin-bottom: 15px; } }
    .intro-main__content__main--h2 {
      font-size: 72px;
      font-weight: bold;
      text-align: center;
      line-height: 95px;
      margin-bottom: 3.5vh; }
      @media (max-width: 1200.98px) {
        .intro-main__content__main--h2 {
          font-size: 6vw;
          line-height: 6.8vw; } }
      @media (max-width: 768.98px) {
        .intro-main__content__main--h2 {
          font-size: 7.8vw;
          line-height: 10vw; } }
      @media (max-width: 575.98px) {
        .intro-main__content__main--h2 {
          margin-bottom: 30px; } }
    .intro-main__content__main--p3 {
      font-size: 2.1rem;
      font-weight: bold;
      line-height: 40px;
      letter-spacing: 2px; }
      .intro-main__content__main--p3 span {
        font-size: 2.6rem; }
        @media (max-width: 575.98px) {
          .intro-main__content__main--p3 span {
            font-size: 20px; } }
      @media (max-width: 768.98px) {
        .intro-main__content__main--p3 {
          font-size: 22px;
          line-height: 32px;
          letter-spacing: 1px; } }
      @media (max-width: 575.98px) {
        .intro-main__content__main--p3 {
          font-size: 16px;
          line-height: 23px; } }
      @media (max-width: 375.98px) {
        .intro-main__content__main--p3 {
          font-size: 15px; } }
  .intro-main__content__action {
    position: relative;
    margin-top: 12vh;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 768.98px) {
      .intro-main__content__action {
        margin-top: 60px;
        width: 90%; } }
    .intro-main__content__action img {
      position: absolute;
      top: -120px;
      z-index: 9999;
      right: -50px;
      width: auto;
      height: auto; }
      @media (max-width: 1200.98px) {
        .intro-main__content__action img {
          right: -50px;
          width: 188px;
          top: -100px; } }
      @media (max-width: 1079.98px) {
        .intro-main__content__action img {
          right: -70px; } }
      @media (max-width: 768.98px) {
        .intro-main__content__action img {
          right: -16%;
          top: -120px;
          width: 40%; } }
      @media (max-width: 575.98px) {
        .intro-main__content__action img {
          top: -50px; } }

.ksb-web-created-logo {
  color: #ffffff;
  display: block; }
  .ksb-web-created-logo p {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 8px; }
    @media (max-width: 575.98px) {
      .ksb-web-created-logo p {
        font-size: 10px; } }
  .ksb-web-created-logo h1 {
    font-weight: bold;
    font-size: 30px; }
    @media (max-width: 575.98px) {
      .ksb-web-created-logo h1 {
        font-size: 25px; } }

.section-overview {
  height: unset;
  position: relative; }

.overview {
  margin-top: -202px; }
  @media (max-width: 768.98px) {
    .overview {
      margin-top: -160px; } }
  .overview__up {
    padding-bottom: 40px;
    background-color: #FFFFFF;
    border-radius: 40px;
    margin-bottom: 132px; }
    @media (max-width: 768.98px) {
      .overview__up {
        padding-bottom: 0px;
        margin-bottom: 70px; } }
    @media (max-width: 575.98px) {
      .overview__up {
        margin-bottom: 35px; } }
    .overview__up__image {
      display: flex;
      position: relative;
      justify-content: center;
      margin-bottom: 64px; }
      @media (max-width: 575.98px) {
        .overview__up__image {
          display: block;
          text-align: center;
          margin-bottom: 25px; } }
      .overview__up__image img {
        margin-top: -64px; }
        @media (max-width: 768.98px) {
          .overview__up__image img {
            width: 78%;
            margin-top: -10%; } }
        @media (max-width: 575.98px) {
          .overview__up__image img {
            width: 80%;
            margin-top: -10%; } }
    .overview__up__title {
      margin-bottom: 84px; }
      .overview__up__title .up {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px; }
        .overview__up__title .up .up-image span:nth-child(1) {
          font-size: 12.8rem;
          font-weight: bold; }
        .overview__up__title .up .up-image span:nth-child(3) {
          font-size: 12.8rem;
          font-weight: bold; }
        .overview__up__title .up span {
          font-weight: 300;
          line-height: 87px; }
          .overview__up__title .up span:nth-child(1) {
            font-size: 6.2rem; }
          .overview__up__title .up span:nth-child(3) {
            font-size: 5.8rem; }
      .overview__up__title p {
        text-align: center; }
        .overview__up__title p:nth-child(1) {
          font-size: 6.2rem;
          font-weight: bold;
          line-height: 87px; }
        .overview__up__title p:nth-child(2) {
          font-size: 5rem;
          font-weight: bold;
          line-height: 70px; }
    .overview__up__content {
      display: flex; }
      @media (max-width: 768.98px) {
        .overview__up__content {
          flex-direction: column;
          justify-content: center;
          align-items: center; } }
      @media (max-width: 768.98px) {
        .overview__up__content {
          padding: 0 8% 50px 8%; } }
      @media (max-width: 575.98px) {
        .overview__up__content {
          padding: 0 5% 35px 5%; } }
      .overview__up__content__item {
        width: 33.33%;
        display: flex;
        flex-direction: column;
        align-items: center; }
        @media (max-width: 768.98px) {
          .overview__up__content__item {
            width: 100%;
            margin-bottom: 20px; } }
        @media (max-width: 575.98px) {
          .overview__up__content__item {
            align-items: unset; } }
        .overview__up__content__item__title {
          font-size: 3.6rem;
          font-weight: bold;
          line-height: 38px;
          margin-bottom: 18px;
          line-height: 38px;
          text-align: center; }
          @media (max-width: 768.98px) {
            .overview__up__content__item__title {
              font-size: 3.7rem; } }
          @media (max-width: 575.98px) {
            .overview__up__content__item__title {
              font-size: 18px;
              text-align: center;
              line-height: 26px; } }
        .overview__up__content__item__body {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          @media (max-width: 768.98px) {
            .overview__up__content__item__body {
              flex-direction: row;
              width: 100%;
              align-items: center;
              margin-top: 10px; } }
          @media (max-width: 575.98px) {
            .overview__up__content__item__body {
              margin-top: 0; } }
          .overview__up__content__item__body p {
            text-align: justify;
            font-size: 1.8rem;
            font-weight: 300;
            padding: 0 40px;
            letter-spacing: 0.4px;
            line-height: 28px; }
            @media (max-width: 768.98px) {
              .overview__up__content__item__body p {
                padding: 0 0 0 0;
                font-size: 2.5rem;
                line-height: 32px; } }
            @media (max-width: 575.98px) {
              .overview__up__content__item__body p {
                padding: 0;
                font-size: 14px;
                line-height: 25px;
                flex: 1; } }
          .overview__up__content__item__body__thumbnail {
            min-height: 180px;
            display: flex;
            justify-content: center;
            align-items: center; }
            @media (max-width: 768.98px) {
              .overview__up__content__item__body__thumbnail {
                min-height: unset;
                margin-top: 0px;
                flex-basis: 30%;
                margin-right: 40px; } }
            @media (max-width: 575.98px) {
              .overview__up__content__item__body__thumbnail {
                margin-top: 0px;
                flex-basis: 30%;
                margin-right: 20px; } }
            @media (max-width: 768.98px) {
              .overview__up__content__item__body__thumbnail img {
                max-width: 124px; } }
            @media (max-width: 575.98px) {
              .overview__up__content__item__body__thumbnail img {
                max-width: none;
                width: 100%; } }
        .overview__up__content__item--dotted {
          position: relative; }
          @media (min-width: 769px) {
            .overview__up__content__item--dotted::after {
              content: "";
              position: absolute;
              z-index: 9999;
              top: 0;
              bottom: 0;
              right: 0;
              border-left: 1px dotted #000000; }
            .overview__up__content__item--dotted::before {
              content: "";
              position: absolute;
              z-index: 9999;
              top: 0;
              bottom: 0;
              left: 0;
              border-left: 1px dotted #000000; } }
  .overview__down {
    text-align: center;
    width: 100%; }
    @media (max-width: 768.98px) {
      .overview__down img {
        width: 100%; } }

.up-title-md {
  font-size: 2.4rem !important;
  font-weight: bold;
  line-height: 38px; }
  @media (max-width: 768.98px) {
    .up-title-md {
      font-size: 2.5rem !important; } }
  @media (max-width: 575.98px) {
    .up-title-md {
      font-size: 14px !important;
      line-height: 24px; } }

.section-production {
  position: relative;
  z-index: 0;
  max-width: 100%;
  overflow-x: hidden;
  padding-top: 80px; }
  .section-production::before {
    content: "";
    width: 120%;
    height: 140%;
    background-color: white;
    position: absolute;
    z-index: -1;
    clip-path: ellipse(55% 25% at 50% 60%);
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1; }
    @media (max-width: 768.98px) {
      .section-production::before {
        width: 168%; } }
  .section-production::after {
    width: 100%;
    height: 70%;
    content: "";
    position: absolute;
    background-color: white;
    bottom: 0;
    z-index: -1; }

.production-content {
  position: relative;
  padding-bottom: 70px; }
  @media (max-width: 768.98px) {
    .production-content {
      padding-bottom: 60px; } }
  .production-content__des {
    position: absolute;
    background-color: #FFFFFF;
    width: 700px;
    height: 120px;
    border-radius: 20px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 768.98px) {
      .production-content__des {
        width: fit-content;
        padding: 20px;
        max-width: 90%;
        height: auto; } }
    @media (max-width: 575.98px) {
      .production-content__des {
        padding: 20px 10px;
        width: 90%; } }
    .production-content__des p {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      line-height: 36px;
      letter-spacing: 0.2rem; }
      @media (max-width: 768.98px) {
        .production-content__des p {
          font-size: 20px;
          line-height: 32px; } }
      @media (max-width: 575.98px) {
        .production-content__des p {
          font-size: 3.5vw;
          line-height: 24px;
          letter-spacing: 0px; } }

.auto-slide {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  width: 100vw;
  overflow: hidden; }
  .auto-slide .box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    width: auto;
    margin: 0;
    padding: 0;
    background-color: transparent; }
    .auto-slide .box img {
      margin-right: 30px;
      width: auto;
      height: auto; }
      @media (max-width: 575.98px) {
        .auto-slide .box img {
          margin-right: 15px; } }
    .auto-slide .box--1 {
      animation: loop 60s -30s linear infinite; }
    .auto-slide .box--2 {
      animation: loop2 60s linear infinite; }

@keyframes loop {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(-100%); } }

@keyframes loop2 {
  0% {
    transform: translateX(0%); }
  100% {
    transform: translateX(-200%); } }

.section-question {
  margin-bottom: 120px;
  padding-top: 130px; }
  @media (max-width: 768.98px) {
    .section-question {
      padding-top: 75px;
      margin-bottom: 80px; } }
  @media (max-width: 575.98px) {
    .section-question {
      padding-top: 35px;
      margin-bottom: 40px; } }

.question__title {
  font-size: 4rem;
  font-weight: bold;
  line-height: 70px;
  text-align: center;
  margin-bottom: 64px; }
  @media (max-width: 768.98px) {
    .question__title {
      font-size: 32px;
      line-height: 40px; } }
  @media (max-width: 768.98px) {
    .question__title {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 15px; } }

.question__item {
  margin-top: 32px;
  padding-top: 40px;
  background-color: #ffffff;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  padding-bottom: 40px; }
  @media (max-width: 1079.98px) {
    .question__item {
      margin-top: 24px; } }
  @media (max-width: 575.98px) {
    .question__item {
      margin-top: 15px;
      padding-top: 25px;
      padding-bottom: 25px; } }
  .question__item--odd {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    margin-right: calc((100vw - 1080px) / 2);
    padding-left: calc((100vw - 1080px) / 2); }
    @media (max-width: 1079.98px) {
      .question__item--odd {
        margin-right: calc((100vw - 78vw) / 2);
        padding-left: calc((100vw - 78vw) / 2); } }
    @media (max-width: 768.98px) {
      .question__item--odd {
        margin-right: calc((100vw - 90vw) / 2);
        padding-left: calc((100vw - 90vw) / 2); } }
  .question__item--even {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-left: calc((100vw - 1080px) / 2);
    padding-right: calc((100vw - 1080px) / 2); }
    @media (max-width: 1079.98px) {
      .question__item--even {
        margin-left: calc((100vw - 78vw) / 2);
        padding-right: calc((100vw - 78vw) / 2); } }
    @media (max-width: 768.98px) {
      .question__item--even {
        margin-left: calc((100vw - 90vw) / 2);
        padding-right: calc((100vw - 90vw) / 2); } }
  .question__item img {
    margin-right: 40px; }
    @media (max-width: 575.98px) {
      .question__item img {
        margin-right: 13px; } }
  .question__item__q {
    display: flex;
    align-items: center;
    padding-bottom: 32px; }
    @media (max-width: 575.98px) {
      .question__item__q {
        padding-bottom: 15px; } }
    .question__item__q span {
      font-size: 2rem;
      font-weight: bold;
      line-height: 30px;
      padding-right: 24px;
      text-align: justify; }
      @media (max-width: 1079.98px) {
        .question__item__q span {
          font-size: 2.1rem;
          line-height: 25px; } }
      @media (max-width: 575.98px) {
        .question__item__q span {
          font-size: 16px;
          line-height: 23px; } }
    .question__item__q--even span {
      padding-right: 0px !important; }
  .question__item__a {
    display: flex;
    align-items: flex-start; }
    .question__item__a span {
      font-size: 1.8rem;
      font-weight: 300;
      line-height: 32px;
      text-align: justify; }
      @media (max-width: 1079.98px) {
        .question__item__a span {
          font-size: 2.1rem;
          line-height: 25px; } }
      @media (max-width: 575.98px) {
        .question__item__a span {
          font-size: 14px;
          line-height: 23px; } }

.question-text--odd {
  padding-right: 80px; }
  @media (max-width: 1079.98px) {
    .question-text--odd {
      padding-right: 24px; } }

.question-text--even {
  padding-left: 80px; }
  @media (max-width: 1079.98px) {
    .question-text--even {
      padding-left: 24px; } }

.section-plan {
  padding-top: 80px;
  position: relative;
  overflow: hidden;
  padding-bottom: 130px;
  background-color: #ffffff;
  z-index: 0; }
  @media (max-width: 768.98px) {
    .section-plan {
      padding-bottom: 80px; } }
  @media (max-width: 575.98px) {
    .section-plan {
      padding-bottom: 40px; } }

.plan__title {
  font-size: 4rem;
  font-weight: bold;
  line-height: 70px;
  margin-bottom: 32px;
  text-align: center; }
  @media (max-width: 768.98px) {
    .plan__title {
      font-size: 3.2rem;
      line-height: 39px; } }
  @media (max-width: 575.98px) {
    .plan__title {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 15px; } }

.plan__desc {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 25px;
  margin-bottom: 40px; }
  @media (max-width: 768.98px) {
    .plan__desc {
      font-size: 2.5rem;
      line-height: 32px; } }
  @media (max-width: 575.98px) {
    .plan__desc {
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 20px; } }

.plan__image {
  margin-bottom: 70px; }
  @media (max-width: 575.98px) {
    .plan__image {
      margin-bottom: 40px; } }

.plan__option {
  display: flex;
  justify-content: center;
  margin-bottom: 16px; }
  .plan__option-desc {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 25px;
    margin-bottom: 16px; }
    @media (max-width: 768.98px) {
      .plan__option-desc {
        font-size: 2.5rem;
        line-height: 32px;
        margin-bottom: 24px; } }
    @media (max-width: 575.98px) {
      .plan__option-desc {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 20px; } }
  .plan__option-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .plan__option-content__item {
      width: calc(20% - 10px);
      min-height: 98px;
      background-color: #F9F6F3;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 21px;
      margin-bottom: 20px; }
      @media (max-width: 768.98px) {
        .plan__option-content__item {
          width: calc(50% - 10px); } }
      @media (max-width: 768.98px) {
        .plan__option-content__item {
          font-size: 2.2rem;
          line-height: 30px;
          margin-bottom: 14px; } }
      @media (max-width: 575.98px) {
        .plan__option-content__item {
          font-size: 14px;
          line-height: 23px;
          width: calc(50% - 5px);
          margin-bottom: 10px;
          min-height: 85px; } }

.pc {
  width: 100%; }
  @media (max-width: 768.98px) {
    .pc {
      display: none; } }

.mobile {
  display: none; }
  @media (max-width: 768.98px) {
    .mobile {
      display: block; }
      .mobile--price {
        width: 100%; } }

.section-problem {
  padding-bottom: 30px;
  padding-top: 95px; }
  @media (max-width: 768.98px) {
    .section-problem {
      padding-bottom: 0;
      padding-top: 35px; } }

.problem__question {
  font-size: 4rem;
  font-weight: bold;
  line-height: 70px;
  margin-bottom: 32px;
  text-align: center; }
  @media (max-width: 768.98px) {
    .problem__question {
      font-size: 3.2rem;
      line-height: 41px; } }
  @media (max-width: 575.98px) {
    .problem__question {
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 20px; } }

.problem__thumbnail {
  margin-bottom: 32px;
  width: 100%; }
  @media (max-width: 575.98px) {
    .problem__thumbnail {
      margin-bottom: 20px; } }

.problem__content {
  margin-bottom: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media (max-width: 575.98px) {
    .problem__content {
      margin-bottom: 25px; } }
  .problem__content__item {
    border-radius: 10px;
    background-color: #FFFFFF;
    margin-bottom: 10px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    width: calc(50% - 10px); }
    @media (max-width: 768.98px) {
      .problem__content__item {
        width: calc(100%); } }
    .problem__content__item__thumbnail {
      background-color: #f7f7f7;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      justify-content: center;
      margin-right: 24px;
      align-items: center; }
      @media (max-width: 575.98px) {
        .problem__content__item__thumbnail {
          margin-right: 0;
          flex-basis: 25%; } }
      @media (max-width: 768.98px) {
        .problem__content__item__thumbnail img {
          width: 100%;
          min-width: 124px; } }
      @media (max-width: 575.98px) {
        .problem__content__item__thumbnail img {
          width: 100%;
          min-width: 100%; } }
    .problem__content__item__body {
      margin-top: 36px;
      margin-right: 24px;
      margin-bottom: 33px; }
      @media (max-width: 575.98px) {
        .problem__content__item__body {
          margin: 0;
          padding: 5%;
          flex: 1; } }
      .problem__content__item__body p:nth-child(1) {
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 34px;
        margin-bottom: 10px; }
        @media (max-width: 768.98px) {
          .problem__content__item__body p:nth-child(1) {
            font-size: 2.5rem;
            line-height: 28px; } }
        @media (max-width: 575.98px) {
          .problem__content__item__body p:nth-child(1) {
            font-size: 16px;
            line-height: 22px; } }
      .problem__content__item__body p:nth-child(2) {
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 25px;
        text-align: justify; }
        @media (max-width: 768.98px) {
          .problem__content__item__body p:nth-child(2) {
            font-size: 2.1rem;
            line-height: 28px; } }
        @media (max-width: 575.98px) {
          .problem__content__item__body p:nth-child(2) {
            font-size: 14px;
            line-height: 22px; } }

.problem__title {
  font-size: 3.3rem;
  font-weight: bold;
  line-height: 50px;
  text-align: center;
  margin-bottom: 38px; }
  @media (max-width: 768.98px) {
    .problem__title {
      font-size: 2.9rem;
      line-height: 39px; } }
  @media (max-width: 575.98px) {
    .problem__title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px; } }

.problem__button {
  display: flex;
  justify-content: center;
  margin-bottom: 80px; }
  @media (max-width: 768.98px) {
    .problem__button {
      margin-bottom: 30px; } }

.section-process {
  background-color: #457CDC;
  padding-bottom: 80px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  margin-top: -1px; }
  @media (max-width: 991.98px) {
    .section-process {
      padding-bottom: 70px; } }
  @media (max-width: 575.98px) {
    .section-process {
      padding-bottom: 35px; } }
  .section-process::before {
    content: "";
    width: 120%;
    height: 120%;
    background-color: #FFFFFF;
    position: absolute;
    z-index: -1;
    clip-path: ellipse(55% 25% at 50% 15%);
    top: 0px;
    left: 50%;
    transform: translateX(-50%); }
    @media (max-width: 991.98px) {
      .section-process::before {
        width: 140%;
        clip-path: ellipse(55% 25% at 50% -8%); } }
    @media (max-width: 575.98px) {
      .section-process::before {
        width: 180%;
        clip-path: ellipse(55% 25% at 50% -11%); } }
  .section-process__content {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 0; }
    @media (max-width: 991.98px) {
      .section-process__content {
        flex-direction: column;
        margin-top: 30px; } }
    .section-process__content::before {
      content: "";
      position: absolute;
      width: 95%;
      height: 20px;
      background-color: #FFE200;
      z-index: -1;
      top: 50%;
      transform: translateY(-50%);
      left: 0; }
      @media (max-width: 991.98px) {
        .section-process__content::before {
          height: 90%;
          width: 20px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%); } }
    .section-process__content .process-item {
      background-color: #ffffff;
      border-radius: 20px;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
      padding: 35px 15px 35px 15px;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: calc((100% / 5) - 40px); }
      @media (max-width: 991.98px) {
        .section-process__content .process-item {
          width: 100%;
          flex-direction: row;
          margin-bottom: 20px;
          padding: 5% 5% 5% 0; } }
      .section-process__content .process-item__top {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 15px;
        height: 135px; }
        @media (max-width: 991.98px) {
          .section-process__content .process-item__top {
            height: auto;
            margin-bottom: 0;
            width: 40%; } }
        .section-process__content .process-item__top h1 {
          font-family: "Roboto", sans-serif;
          font-size: 62px;
          font-weight: bold;
          color: #457CDC;
          margin-bottom: 30px; }
          @media (max-width: 991.98px) {
            .section-process__content .process-item__top h1 {
              font-size: 40px;
              margin-bottom: 3px; } }
        .section-process__content .process-item__top p {
          font-weight: bold;
          text-align: center;
          line-height: 22px; }
          @media (max-width: 991.98px) {
            .section-process__content .process-item__top p {
              font-size: 20px; } }
          @media (max-width: 575.98px) {
            .section-process__content .process-item__top p {
              font-size: 16px; } }
      @media (max-width: 991.98px) {
        .section-process__content .process-item__bot {
          flex: 1; } }
      .section-process__content .process-item__bot p {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        text-align: justify; }
        @media (max-width: 991.98px) {
          .section-process__content .process-item__bot p {
            font-size: 21px;
            line-height: 32px; } }
        @media (max-width: 575.98px) {
          .section-process__content .process-item__bot p {
            font-size: 14px;
            line-height: 23px; } }
    .section-process__content .last_process {
      border-radius: 40px;
      background-color: #FFE200;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-basis: 72px; }
      @media (max-width: 991.98px) {
        .section-process__content .last_process {
          border-radius: 20px; } }
      @media (max-width: 575.98px) {
        .section-process__content .last_process {
          flex-basis: 42px; } }
      .section-process__content .last_process p {
        font-size: 28px;
        font-weight: bold;
        writing-mode: vertical-rl; }
        @media (max-width: 991.98px) {
          .section-process__content .last_process p {
            writing-mode: unset; } }
        @media (max-width: 575.98px) {
          .section-process__content .last_process p {
            font-size: 18px; } }

.header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 5%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100%;
  transition: all 0.2s ease;
  opacity: 0;
  visibility: hidden; }
  @media (max-width: 575.98px) {
    .header {
      padding: 15px 5%; } }
  .header h1 {
    font-size: 30px; }
    @media (max-width: 575.98px) {
      .header h1 {
        font-size: 16px; } }
  .header .main-btn {
    height: 65px;
    width: 400px;
    font-size: 22px; }
    @media (max-width: 575.98px) {
      .header .main-btn {
        width: 210px;
        height: 40px;
        font-size: 13px; } }
    @media (max-width: 375.98px) {
      .header .main-btn {
        width: 180px; } }
    .header .main-btn img {
      width: 25px;
      right: 20px; }
      @media (max-width: 575.98px) {
        .header .main-btn img {
          width: 16px;
          right: 10px; } }
      @media (max-width: 375.98px) {
        .header .main-btn img {
          display: none; } }

.section-thank {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px 5%;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100%;
  transition: all 0.2s ease;
  min-height: calc(100vh - 319px); }
  @media (max-width: 575.98px) {
    .section-thank {
      padding: 15px 5%; } }
  .section-thank h1 {
    font-size: 30px; }
    @media (max-width: 575.98px) {
      .section-thank h1 {
        font-size: 16px; } }

.thank__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 142px; }
  @media (max-width: 768.98px) {
    .thank__body {
      margin-top: 60px; } }
  .thank__body__title {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 55px;
    margin-bottom: 46px; }
    @media (max-width: 768.98px) {
      .thank__body__title {
        margin-top: 0px;
        font-size: 2rem; } }
  .thank__body__content {
    margin-bottom: 40px; }
    .thank__body__content p {
      font-size: 2.5rem;
      font-weight: 300;
      line-height: 35px;
      text-align: center; }
      @media (max-width: 768.98px) {
        .thank__body__content p {
          font-size: 1.6rem; } }

.btn-back {
  width: 412px;
  height: 82px;
  font-size: 2.2rem;
  margin-bottom: 48px; }

.disable-button {
  display: none; }

.top-contact-btn {
  display: block;
  width: 414px;
  height: 100px;
  background-color: #FFE200;
  border-radius: 63px;
  color: inherit;
  position: relative;
  z-index: 9999; }
  @media (max-width: 768.98px) {
    .top-contact-btn {
      width: 100%;
      height: 80px; } }
  .top-contact-btn:hover::before {
    top: 0; }
  .top-contact-btn::before {
    position: absolute;
    content: "ご相談・資料請求";
    width: 100%;
    font-size: 3rem;
    height: 100%;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    border-radius: inherit;
    z-index: 2;
    top: -12px;
    transition: all .2s; }
    @media (max-width: 768.98px) {
      .top-contact-btn::before {
        font-size: 20px; } }
    @media (max-width: 575.98px) {
      .top-contact-btn::before {
        font-size: 20px;
        justify-content: flex-start;
        padding-left: 38px; } }
  .top-contact-btn::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #0C3178;
    border-radius: inherit;
    z-index: -1; }

.main-btn, button.main-btn {
  border: none;
  cursor: pointer;
  width: 534px;
  height: 100px;
  border-radius: 63px;
  display: block;
  color: inherit;
  font-size: 3rem;
  letter-spacing: .4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  position: relative;
  z-index: 0;
  max-width: 100%; }
  @media (max-width: 768.98px) {
    .main-btn, button.main-btn {
      font-size: 20px;
      letter-spacing: 0px; } }
  @media (max-width: 575.98px) {
    .main-btn, button.main-btn {
      font-size: 16px;
      height: 65px; } }
  .main-btn img, button.main-btn img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px; }
    @media (max-width: 768.98px) {
      .main-btn img, button.main-btn img {
        width: 25px; } }
    @media (max-width: 575.98px) {
      .main-btn img, button.main-btn img {
        right: 10px; } }
  .main-btn:hover::before, button.main-btn:hover::before {
    width: 105%;
    height: 105%; }
  .main-btn::before, button.main-btn::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: #FFE200;
    transition: all .2s;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1; }
